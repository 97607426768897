import React, { useState } from "react";
import CanvasRoundedSquare from "./CanvasRoundedSquare";
import PositionedImage from "./PositionedImage";
import PositionedText from "./PositionedText";
import UploadModal from "./UploadModal";

import axios from "axios";

const RoundedSquare = ({
  left,
  top,
  width,
  height,
  imgSrc,
  isSelected,
  onClick,
  showBorder,
}) => (
  <div
    className={`absolute rounded-lg ${
      showBorder
        ? isSelected
          ? "border-2 border-red-500"
          : "border-2 border-black"
        : ""
    } square`}
    style={{
      left: `calc(${left} / 3817 * 100%)`,
      top: `calc(${top} / 2158 * 100%)`,
      width: `calc(${width} / 3817 * 100%)`,
      height: `calc(${height} / 2158 * 100%)`,
      backgroundImage: `url(${imgSrc})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      cursor: "pointer",
    }}
    onClick={onClick}
  />
);

const Arrow = ({ left, top, onClick, flip }) => (
  <svg
    className="absolute arrow cursor-pointer"
    width="calc(40 / 3817 * 100%)"
    height="calc(40 / 2158 * 100%)"
    viewBox="0 0 150 270"
    style={{
      left: `calc(${left} / 3817 * 100%)`,
      top: `calc(${top} / 2158 * 100%)`,
    }}
    onClick={onClick}
  >
    <polygon
      points="0,135 150,0 150,270"
      fill="black"
      transform={flip ? "scale(-1, 1) translate(-150, 0)" : ""}
    />
  </svg>
);

const InputField = ({ left, top, width, value, onChange }) => (
  <input
    type="text"
    className="absolute input-field"
    style={{
      left: `calc(${left} / 3817 * 100%)`,
      top: `calc(${top} / 2158 * 100%)`,
      width: `calc(${width} / 3817 * 100%)`,
    }}
    value={value}
    onChange={onChange}
  />
);

const Main = () => {
  const squareWidth = 1531 - 1258;
  const squareHeight = 1020 - 828;
  const spaceBetween = 53;
  const verticalSpace = 33;
  const horizontalOffset = 18;

  const dadHeads = ["/dad_heads/1.png", "/dad_heads/2.png", "/dad_heads/3.png"];
  const childHeads = [
    "/child_heads/1.png",
    "/child_heads/2.png",
    "/child_heads/3.png",
  ];
  const initialBackgrounds = [
    "/backgrounds/4.png",
    "/backgrounds/2.png",
    "/backgrounds/1.png",
  ];
  const uploadImage = "/upload.png";

  const [selectedImages, setSelectedImages] = useState([
    [uploadImage, ...dadHeads],
    [uploadImage, ...childHeads],
  ]);

  const [selectedIndices, setSelectedIndices] = useState([0, 0]);
  const [selectedSquare, setSelectedSquare] = useState([
    [0, 1],
    [0, 1],
  ]); // [[row1, col1], [row2, col2]]
  const [backgrounds, setBackgrounds] = useState(initialBackgrounds);
  const [selectedBackgroundIndex, setSelectedBackgroundIndex] = useState(0);
  const [inputText, setInputText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadType, setUploadType] = useState("dad");

  const handleArrowClick = (row, direction) => {
    setSelectedIndices((prevIndices) => {
      const newIndices = [...prevIndices];
      newIndices[row] =
        (newIndices[row] + direction + (selectedImages[row].length - 1)) %
        (selectedImages[row].length - 1);
      return newIndices;
    });
  };

  const handleBackgroundArrowClick = (direction) => {
    setSelectedBackgroundIndex(
      (prevIndex) =>
        (prevIndex + direction + backgrounds.length) % backgrounds.length
    );
  };

  const handleSquareClick = (row, col) => {
    if (col === 0) {
      setUploadType(row === 0 ? "dad" : "son");
      setIsModalOpen(true);
    } else {
      setSelectedSquare((prevSelectedSquare) => {
        const newSelectedSquare = [...prevSelectedSquare];
        newSelectedSquare[row] = [row, col];
        return newSelectedSquare;
      });
    }
  };

  const getDisplayedImage = (row, col) => {
    if (col === 0) {
      return selectedImages[row][0]; // Always return the upload image for the first square
    }
    const imageIndex =
      1 + ((selectedIndices[row] + col - 1) % (selectedImages[row].length - 1));
    return selectedImages[row][imageIndex];
  };

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleUploadSave = (croppedImage, uploadType) => {
    if (uploadType === "background") {
      setBackgrounds((prevBackgrounds) => {
        const newBackgrounds = [croppedImage, ...prevBackgrounds];
        setSelectedBackgroundIndex(0);
        return newBackgrounds;
      });
    } else {
      setSelectedImages((prevImages) => {
        const newImages = [...prevImages];
        const rowIndex = uploadType === "dad" ? 0 : 1;
        newImages[rowIndex] = [
          newImages[rowIndex][0],
          croppedImage,
          ...newImages[rowIndex].slice(1),
        ];
        setSelectedIndices((prevIndices) => {
          const newIndices = [...prevIndices];
          newIndices[rowIndex] = 0;
          return newIndices;
        });
        setSelectedSquare((prevSelectedSquare) => {
          const newSelectedSquare = [...prevSelectedSquare];
          newSelectedSquare[rowIndex] = [rowIndex, 1];
          return newSelectedSquare;
        });
        return newImages;
      });
    }
    setIsModalOpen(false);
  };

  const handleSave = () => {
    const canvas = document.querySelector("canvas");
    const link = document.createElement("a");
    link.href = canvas.toDataURL("image/png");
    link.download = "canvas.png";
    link.click();
  };

  const handleTweet = async () => {
    const canvas = document.querySelector("canvas");
    const dataURL = canvas.toDataURL("image/png"); // Convert canvas to data URL

    try {
      const response = await axios.post("https://split.texaglo.com:5000/tweet-image", {
        image: dataURL, // Send the image data URL to the backend
      });
      window.location.href = response.data.authUrl; // Redirect the user to Twitter's auth page
    } catch (error) {
      console.error("Error during OAuth:", error);
    }
  };

  return (
    <div className="min-h-screen w-screen bg-white flex flex-col items-center overflow-hidden">
      <div
        className="scale-container w-full max-w-[2560px] mt-8 relative"
        style={{ aspectRatio: "3817 / 2158" }}
      >
        <img
          src="/top-ui.png"
          alt="Top UI"
          className="w-full h-full object-contain absolute"
        />
        <div className="absolute inset-0">
          {[0, 1].map((row) =>
            [0, 1, 2, 3].map((col) => (
              <RoundedSquare
                key={`${row}-${col}`}
                left={
                  1258 + horizontalOffset + col * (squareWidth + spaceBetween)
                }
                top={828 + row * (squareHeight + verticalSpace)}
                width={squareWidth}
                height={squareHeight}
                imgSrc={getDisplayedImage(row, col)}
                isSelected={selectedSquare[row][1] === col}
                onClick={() => handleSquareClick(row, col)}
                showBorder={col !== 0}
              />
            ))
          )}

          {/* New CanvasRoundedSquare with meme.png and overlay images */}
          <CanvasRoundedSquare
            left={1258}
            top={1331}
            width={1900 - 1258}
            height={1787 - 1331}
            imgSrc="/meme.png"
            overlayImgSrc={getDisplayedImage(0, selectedSquare[0][1])}
            overlayLeft={75}
            overlayTop={154}
            overlayWidth={95}
            overlayHeight={81}
            childHeadImgSrc={getDisplayedImage(1, selectedSquare[1][1])}
            childHeadLeft={389}
            childHeadTop={234}
            childHeadWidth={60}
            childHeadHeight={59}
            text={inputText}
            textLeft={398}
            textTop={117}
            textWidth={245}
            textHeight={98}
            kidsBackgroundSrc={backgrounds[selectedBackgroundIndex]}
            kidsBackgroundLeft={525}
            kidsBackgroundTop={251}
            kidsBackgroundWidth={46}
            kidsBackgroundHeight={47}
            kidsBackgroundRotation={15}
          />

          {/* New CanvasRoundedSquare with rotating backgrounds */}
          <CanvasRoundedSquare
            left={2046}
            top={1395}
            width={2367 - 2057}
            height={1560 - 1395}
            imgSrc={backgrounds[selectedBackgroundIndex]}
          />

          {/* Positioned Images */}
          <PositionedImage
            src="/save.png"
            left={1706}
            top={1888}
            width={1900 - 1706}
            height={1950 - 1888}
            onClick={handleSave} // Attach handleSave function here
          />
          <PositionedImage
            src="/tweet.png"
            left={1928}
            top={1888}
            width={2123 - 1928}
            height={1950 - 1888}
            onClick={handleTweet}
          />

          {/* Positioned Text */}
          <PositionedText
            text="CHOOSE THE SON'S BACKGROUND"
            left={2000}
            top={1325}
            fontSize={14}
            fontFamily="Helvetica LT Std Black"
          />

          <PositionedText
            text="CHOOSE THE SON'S RESPONSE"
            left={2000}
            top={1590}
            fontSize={14}
            fontFamily="Helvetica LT Std Black"
          />

          {/* Input Field */}
          <InputField
            left={2046}
            top={1620}
            width={310}
            value={inputText}
            onChange={handleInputChange}
          />

          {/* Left Arrows for New CanvasRoundedSquare */}
          <Arrow
            left={2046 - 70}
            top={1395 + (1560 - 1395) / 2 - 25}
            onClick={() => handleBackgroundArrowClick(-1)}
            flip={false}
          />

          {/* Right Arrows for New CanvasRoundedSquare */}
          <Arrow
            left={2376}
            top={1395 + (1560 - 1395) / 2 - 25}
            onClick={() => handleBackgroundArrowClick(1)}
            flip={true}
          />

          {/* Left Arrows */}
          <Arrow
            left={1590 + horizontalOffset - 70}
            top={
              828 + 0 * (squareHeight + verticalSpace) + squareHeight / 2 - 25
            }
            onClick={() => handleArrowClick(0, -1)}
            flip={false}
          />
          <Arrow
            left={1590 + horizontalOffset - 70}
            top={
              828 + 1 * (squareHeight + verticalSpace) + squareHeight / 2 - 25
            }
            onClick={() => handleArrowClick(1, -1)}
            flip={false}
          />

          {/* Right Arrows */}
          <Arrow
            left={1258 + 4 * (squareWidth + spaceBetween) - 10}
            top={
              828 + 0 * (squareHeight + verticalSpace) + squareHeight / 2 - 25
            }
            onClick={() => handleArrowClick(0, 1)}
            flip={true}
          />
          <Arrow
            left={1258 + 4 * (squareWidth + spaceBetween) - 10}
            top={
              828 + 1 * (squareHeight + verticalSpace) + squareHeight / 2 - 25
            }
            onClick={() => handleArrowClick(1, 1)}
            flip={true}
          />
        </div>
      </div>
      <UploadModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleUploadSave}
        uploadType={uploadType}
        setUploadType={setUploadType}
      />
    </div>
  );
};

export default Main;
