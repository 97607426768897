import React, { useEffect, useRef } from 'react';

const CanvasRoundedSquare = ({ left, top, width, height, imgSrc, overlayImgSrc, overlayLeft, overlayTop, overlayWidth, overlayHeight, childHeadImgSrc, childHeadLeft, childHeadTop, childHeadWidth, childHeadHeight, text, textLeft, textTop, textWidth, textHeight, kidsBackgroundSrc, kidsBackgroundLeft, kidsBackgroundTop, kidsBackgroundWidth, kidsBackgroundHeight, kidsBackgroundRotation }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Enable anti-aliasing
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';

    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw a white background
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Draw the main image
    const img = new Image();
    img.src = imgSrc;
    img.onload = () => {
      ctx.drawImage(img, 0, 0, width, height);

      // Draw the overlay image
      if (overlayImgSrc) {
        const overlayImg = new Image();
        overlayImg.src = overlayImgSrc;
        overlayImg.onload = () => {
          ctx.drawImage(overlayImg, overlayLeft, overlayTop, overlayWidth, overlayHeight);
        };
      }

      // Draw the child head image
      if (childHeadImgSrc) {
        const childHeadImg = new Image();
        childHeadImg.src = childHeadImgSrc;
        childHeadImg.onload = () => {
          ctx.drawImage(childHeadImg, childHeadLeft, childHeadTop, childHeadWidth, childHeadHeight);
        };
      }

      // Draw the kids' background
      if (kidsBackgroundSrc) {
        const kidsBackgroundImg = new Image();
        kidsBackgroundImg.src = kidsBackgroundSrc;
        kidsBackgroundImg.onload = () => {
          ctx.save();
          ctx.translate(kidsBackgroundLeft + (kidsBackgroundWidth * 1.3) / 2, kidsBackgroundTop + (kidsBackgroundHeight * 1.3) / 2);
          ctx.rotate((kidsBackgroundRotation * Math.PI) / 180);
          ctx.drawImage(kidsBackgroundImg, -(kidsBackgroundWidth * 1.3) / 2, -(kidsBackgroundHeight * 1.3) / 2, kidsBackgroundWidth * 1.3, kidsBackgroundHeight * 1.3);
          ctx.restore();
        };
      }

      // Draw the text
      if (text) {
        ctx.font = '14px "Helvetica LT Std Black"';
        ctx.fillStyle = 'black';
        ctx.textBaseline = 'top';
        const lines = wrapText(ctx, text, textWidth);
        const totalHeight = lines.length * 20;
        let adjustedTextTop = textTop + textHeight - totalHeight; // Start lower and move up as text wraps
        adjustedTextTop = Math.max(textTop, adjustedTextTop); // Ensure it doesn't go above the original top
        lines.forEach((line, index) => {
          ctx.fillText(line, textLeft, adjustedTextTop + index * 20);
        });
      }
    };
  }, [width, height, imgSrc, overlayImgSrc, overlayLeft, overlayTop, overlayWidth, overlayHeight, childHeadImgSrc, childHeadLeft, childHeadTop, childHeadWidth, childHeadHeight, text, textLeft, textTop, textWidth, textHeight, kidsBackgroundSrc, kidsBackgroundLeft, kidsBackgroundTop, kidsBackgroundWidth, kidsBackgroundHeight, kidsBackgroundRotation]);

  const wrapText = (ctx, text, maxWidth) => {
    const words = text.split(' ');
    let line = '';
    const lines = [];

    for (let n = 0; n < words.length; n++) {
      const testLine = line + words[n] + ' ';
      const testWidth = ctx.measureText(testLine).width;
      if (testWidth > maxWidth && n > 0) {
        lines.push(line);
        line = words[n] + ' ';
      } else {
        line = testLine;
      }
    }
    lines.push(line);
    return lines;
  };

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      className="absolute"
      style={{
        left: `calc(${left} / 3817 * 100%)`,
        top: `calc(${top} / 2158 * 100%)`,
        width: `calc(${width} / 3817 * 100%)`,
        height: `calc(${height} / 2158 * 100%)`,
        border: imgSrc ? '2px solid black' : 'none', // Add border if imgSrc is provided
      }}
    ></canvas>
  );
};

export default CanvasRoundedSquare;
