import React, { useState, useEffect } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const UploadModal = ({ isOpen, onClose, onSave, uploadType, setUploadType }) => {
  const [image, setImage] = useState(null);
  const [cropper, setCropper] = useState(null);

  const handleFileChange = (e) => {
    e.preventDefault();
    const files = e.target.files || e.dataTransfer.files;
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const handleSave = () => {
    if (cropper) {
      const croppedImage = cropper.getCroppedCanvas().toDataURL();
      onSave(croppedImage);
    }
  };

  useEffect(() => {
    setImage(null);
    setCropper(null);
  }, [uploadType]);

  return isOpen ? (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-4 rounded shadow-lg">
        <h2 className="text-lg font-bold mb-4">Upload and Crop Image</h2>
        <div className="mb-4">
          <input type="file" accept="image/*" onChange={handleFileChange} />
          {image && (
            <Cropper
              style={{ height: 400, width: '100%' }}
              initialAspectRatio={uploadType === 'background' ? 16 / 9 : 1}
              aspectRatio={uploadType === 'background' ? 16 / 9 : 1}
              src={image}
              viewMode={1}
              guides={false}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
            />
          )}
        </div>
        <div className="mb-4">
          <label>
            <input
              type="radio"
              name="uploadType"
              value="dad"
              checked={uploadType === 'dad'}
              onChange={() => setUploadType('dad')}
            />
            Dad's Head
          </label>
          <label className="ml-4">
            <input
              type="radio"
              name="uploadType"
              value="child"
              checked={uploadType === 'child'}
              onChange={() => setUploadType('child')}
            />
            Son's Head
          </label>
          <label className="ml-4">
            <input
              type="radio"
              name="uploadType"
              value="background"
              checked={uploadType === 'background'}
              onChange={() => setUploadType('background')}
            />
            Background
          </label>
        </div>
        <div className="flex justify-end">
          <button
            className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded mr-2"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default UploadModal;