import React, { useState, useRef, useEffect } from 'react';
import UploadModal from './UploadModal';

const ImageSelector = ({ title, images, selectedIndex, onSelect, isBackground }) => (
    <div className="image-selector">
        <h2 style={{ fontFamily: 'Helvetica LT Std Black, Arial, sans-serif' }}>{title}</h2>
        <div className={`image-options ${isBackground ? 'background-options' : ''}`}>
            {images.map((img, index) => (
                <img
                    key={index}
                    src={img}
                    alt={`${title} ${index}`}
                    className={`selectable-image ${selectedIndex === index ? 'selected' : ''} ${isBackground ? 'background-image' : ''}`}
                    onClick={() => onSelect(index)}
                />
            ))}
        </div>
    </div>
);

const PhoneMemeGenerator = () => {
    const [dadHeads, setDadHeads] = useState(['/upload.png', '/dad_heads/1.png', '/dad_heads/2.png', '/dad_heads/3.png']);
    const [childHeads, setChildHeads] = useState(['/upload.png', '/child_heads/1.png', '/child_heads/2.png', '/child_heads/3.png']);
    const [backgrounds, setBackgrounds] = useState(['/upload.png', '/backgrounds/1.png', '/backgrounds/2.png']);

    const [selectedDadIndex, setSelectedDadIndex] = useState(1);
    const [selectedChildIndex, setSelectedChildIndex] = useState(1);
    const [selectedBackgroundIndex, setSelectedBackgroundIndex] = useState(1);
    const [inputText, setInputText] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [uploadType, setUploadType] = useState('dad');

    const canvasRef = useRef(null);

    useEffect(() => {
        drawMeme();
    }, [selectedDadIndex, selectedChildIndex, selectedBackgroundIndex, inputText, dadHeads, childHeads, backgrounds]);

    const drawMeme = async () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        // Clear canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Draw base meme image
        const baseImg = await loadImage('/meme.png');
        ctx.drawImage(baseImg, 0, 0, canvas.width, canvas.height);

        // Draw dad head
        const dadImg = await loadImage(dadHeads[selectedDadIndex]);
        ctx.drawImage(dadImg, canvas.width * 0.1168, canvas.height * 0.3377, canvas.width * 0.1480, canvas.height * 0.1776);

        // Draw child head
        const childImg = await loadImage(childHeads[selectedChildIndex]);
        ctx.drawImage(childImg, canvas.width * 0.6059, canvas.height * 0.5132, canvas.width * 0.0935, canvas.height * 0.1294);

        // Draw background
        const bgImg = await loadImage(backgrounds[selectedBackgroundIndex]);
        ctx.save();
        ctx.translate(canvas.width * 0.85, canvas.height * 0.5);
        ctx.rotate(15 * Math.PI / 180);
        ctx.drawImage(bgImg, -canvas.width * 0.05, -canvas.height * 0.0001, canvas.width * 0.15, canvas.height * 0.15);
        ctx.restore();

        // Draw text
        ctx.font = `${canvas.height * 0.04}px Helvetica LT Std Black, Arial, sans-serif`;
        ctx.fillStyle = 'black';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        wrapText(ctx, inputText, canvas.width * 0.81, canvas.height * 0.36, canvas.width * 0.3816, canvas.height * 0.04);
    };

    const loadImage = (src) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = src;
        });
    };

    const wrapText = (ctx, text, x, y, maxWidth, lineHeight) => {
        const words = text.split(' ');
        let line = '';

        for (let n = 0; n < words.length; n++) {
            const testLine = line + words[n] + ' ';
            const metrics = ctx.measureText(testLine);
            const testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
                ctx.fillText(line, x, y);
                line = words[n] + ' ';
                y += lineHeight;
            } else {
                line = testLine;
            }
        }
        ctx.fillText(line, x, y);
    };

    const handleImageSelect = (type, index) => {
        if (index === 0) {
            setUploadType(type);
            setIsModalOpen(true);
        } else {
            if (type === 'dad') setSelectedDadIndex(index);
            if (type === 'child') setSelectedChildIndex(index);
            if (type === 'background') setSelectedBackgroundIndex(index);
        }
    };

    const handleUploadSave = (croppedImage) => {
        if (uploadType === 'background') {
            setBackgrounds(prevBackgrounds => [prevBackgrounds[0], croppedImage, ...prevBackgrounds.slice(1)]);
            setSelectedBackgroundIndex(1);
        } else if (uploadType === 'dad') {
            setDadHeads(prev => [prev[0], croppedImage, ...prev.slice(1)]);
            setSelectedDadIndex(1);
        } else if (uploadType === 'child') {
            setChildHeads(prev => [prev[0], croppedImage, ...prev.slice(1)]);
            setSelectedChildIndex(1);
        }
        setIsModalOpen(false);
    };

    const handleSave = () => {
        const canvas = canvasRef.current;
        const link = document.createElement('a');
        link.download = 'meme.png';
        link.href = canvas.toDataURL();
        link.click();
    };

    return (
        <div className="phone-meme-generator">
            {/* <div className="top-ui-container">
                <img src="/top-ui-phone.png" alt="Top UI" className="top-ui" />
            </div> */}

            <canvas ref={canvasRef} width={642} height={456} style={{ width: '100%', height: 'auto' }} />

            <ImageSelector
                title="Choose Dad's Head"
                images={dadHeads}
                selectedIndex={selectedDadIndex}
                onSelect={(index) => handleImageSelect('dad', index)}
            />

            <ImageSelector
                title="Choose Son's Head"
                images={childHeads}
                selectedIndex={selectedChildIndex}
                onSelect={(index) => handleImageSelect('child', index)}
            />

            <ImageSelector
                title="Choose Background"
                images={backgrounds}
                selectedIndex={selectedBackgroundIndex}
                onSelect={(index) => handleImageSelect('background', index)}
                isBackground={true}
            />

            <div className="text-input border-2 border-gray-300 rounded-lg p-4 my-4">
                <h2 className="text-lg font-bold mb-2" style={{ fontFamily: 'Helvetica LT Std Black, Arial, sans-serif' }}>Enter Son's response</h2>
                <input
                    type="text"
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    placeholder="Your text"
                    className="w-full px-3 py-2 border border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    style={{ fontFamily: 'Helvetica LT Std Black, Arial, sans-serif' }}
                />
            </div>

            <div className="action-buttons">
                <img
                    src={`${process.env.PUBLIC_URL}/save.png`}
                    alt="Save Meme"
                    onClick={handleSave}
                    style={{ cursor: 'pointer', marginRight: '20px', width: '80px', height: '40px' }}
                />
                <img
                    src={`${process.env.PUBLIC_URL}/tweet.png`}
                    alt="Tweet Meme"
                    style={{ cursor: 'pointer', width: '80px', height: '40px' }}
                // onClick={handleTweet} // Uncomment and define this function if needed
                />
            </div>

            <UploadModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSave={handleUploadSave}
                uploadType={uploadType}
                setUploadType={setUploadType}
            />

            <div className="bottom-ui-container">
                <div className="bottom-ui-wrapper">
                    <img src="/phone-ui-art-bottom.png" alt="Bottom UI" className="bottom-ui" />
                </div>
            </div>
        </div>
    );
};

export default PhoneMemeGenerator;