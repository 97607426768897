import React from 'react';

const PositionedText = ({ text, left, top, fontSize, fontFamily }) => (
  <div
    className="absolute positioned-text"
    style={{
      left: `calc(${left} / 3817 * 100%)`,
      top: `calc(${top} / 2158 * 100%)`,
      fontSize: `${fontSize}px`,
      fontFamily: fontFamily,
    }}
  >
    {text}
  </div>
);

export default PositionedText;