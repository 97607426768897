import React, { useState, useEffect } from "react";
import Main from "./components/Main";
import PhoneMemeGenerator from "./components/PhoneMemeGenerator";
import "./index.css";

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust this breakpoint as needed
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return <>{isMobile ? <PhoneMemeGenerator /> : <Main />}</>;
}

export default App;
