import React from 'react';

const PositionedImage = ({ src, left, top, width, height, onClick }) => (
  <img
    src={src}
    alt="Positioned"
    className="absolute positioned-image cursor-pointer" // Added cursor-pointer for better UX
    style={{
      left: `calc(${left} / 3817 * 100%)`,
      top: `calc(${top} / 2158 * 100%)`,
      width: `calc(${width} / 3817 * 100%)`,
      height: `calc(${height} / 2158 * 100%)`,
    }}
    onClick={onClick}
  />
);

export default PositionedImage;
